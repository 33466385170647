import { render, staticRenderFns } from "./EvaluationTab.vue?vue&type=template&id=abe69dd2&scoped=true&"
import script from "./EvaluationTab.vue?vue&type=script&lang=ts&"
export * from "./EvaluationTab.vue?vue&type=script&lang=ts&"
import style0 from "./EvaluationTab.vue?vue&type=style&index=0&id=abe69dd2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abe69dd2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCardActions,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider})
