







































import { Vue, Component } from 'vue-property-decorator';
import NavigationReportChart from '@/components/navigationReport/NavigationReportChart.vue';
import { namespace } from 'vuex-class';
import { SessionInfo, SessionInfoParams } from '@/models/EvaluationModels';
import ImageUserProfile from '@/components/shared/ImageUserProfile.vue';
import EvaluationTab from '@/components/coursesPlanner/evaluation/EvaluationTab.vue';
import BreadCrumbs from '@/components/shared/BreadCrumbs.vue';
import { UIBreadcrumb } from '@/models/UIModels';
import { CourseInfo } from '@/models/CoursesModels';

const evaluationModule = namespace('EvaluationModule');
const coursesTemplateModule = namespace('CoursesTemplateModule');

@Component({
  components: {
    NavigationReportChart,
    ImageUserProfile,
    EvaluationTab,
    BreadCrumbs,
  },
})
export default class NavigationReportLanding extends Vue {
  @evaluationModule.Getter
  public currentSessionInfo!: SessionInfo | null;

  @evaluationModule.Mutation
  public setCurrentSessionInfo!: (sessionInfo: SessionInfo | null) => void;

  @evaluationModule.Action
  public fetchSessionInfo!: (sessionInfoParams: SessionInfoParams) => Promise<void>;

  @coursesTemplateModule.Getter
  public currentCourseInfo!: CourseInfo | null;

  @coursesTemplateModule.Action
  public fetchCoursesInfosById!: (idCourse: string) => Promise<void>;

  private tab = null;

  public created() {
    this.fetchData();
  }

  private async fetchData() {
    await this.fetchSessionInfo(this.sessionInfoParams);
    await this.fetchCoursesInfosById(this.$route.params.courseId);
  }

  get sessionInfoParams(): SessionInfoParams {
    return {
      lessonId: this.$route.params.lessonId,
      userId: this.$route.params.userId,
    };
  }

  public get breadCrumbsItems(): UIBreadcrumb[] {
    return [
      {
        text: this.$t('coursesPlanner.title') as string,
        to: '/courses-planner',
        exact: true,
      },
      {
        text: this.currentCourseInfo ? this.currentCourseInfo.title : '',
        to: '/courses-planner/' + this.$route.params.courseId,
        exact: true,
      },
      {
        text: this.currentSessionInfo!.userName ? this.currentSessionInfo!.userName : '',
        to: '/courses-planner/' + this.$route.params.courseId + '/' + this.currentSessionInfo?.userId,
        exact: true,
      },
    ];
  }
}
