











































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Evaluation, EvaluationParams } from '@/models/EvaluationModels';
import { namespace } from 'vuex-class';
import UserMixin from '@/mixins/UserMixin';
import { CourseInfo } from '@/models/CoursesModels';
import { mixins } from 'vue-class-component';

const evaluationModule = namespace('EvaluationModule');
const coursesTemplateModule = namespace('CoursesTemplateModule');

@Component({})
export default class EvaluationForm extends mixins(UserMixin) {
  public isSavingGrade: boolean = false;
  public isFormValid: boolean = false;

  public requiredRules: any[] = [
    (v) => !!v || this.getErrorMessage('common.error.fieldRequired'),
  ];

  public hasSucceeded: boolean = false;
  public feedback: string = '';
  public grade: number = 0;
  public formOnReadOnly: boolean = true;

  @coursesTemplateModule.Getter
  public currentCourseInfo!: CourseInfo | null;

  @evaluationModule.Getter
  public evaluation!: Evaluation | null;

  @evaluationModule.Mutation
  public setEvaluation!: (evaluation: Evaluation | null) => void;

  @evaluationModule.Action
  public fetchEvaluation!: (evaluationParams: EvaluationParams) => Promise<void>;

  @evaluationModule.Action
  public createEvaluation!: (evaluation: Evaluation) => Promise<void>;

  @Prop({
    required: true,
  })
  public userId!: string;

  @Prop({
    required: true,
  })
  public lessonId!: string;

  public created(): void {
    this.fetchEvaluation(this.evaluationParams).then(() => {
      if (this.evaluation && this.evaluation.score) {
        this.hasSucceeded = this.evaluation.score.success;
        this.feedback = this.evaluation.score.feedBack;
        this.grade = this.evaluation.score.grade;
      }
      if (this.isAdmin || this.isFleetChief || this.isInstructor) {
        if (!this.evaluation) {
          this.formOnReadOnly = false;
        }
      }
    });
  }

  public destroyed() {
    this.setEvaluation(null);
  }

  public save(): void {
    this.isFormValid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
    if (this.isFormValid) {
      this.isSavingGrade = true;
      this.createEvaluation(this.currentEvaluation).then(() => this.isSavingGrade = false);
    }
  }

  public getErrorMessage(key: string, args?: any): string {
    return this.$t(key, args) as string;
  }

  get evaluationParams(): EvaluationParams {
    return {
      userId: this.userId,
      lessonId: this.lessonId,
      courseId: this.currentCourseInfo?.id,
    };
  }

  get currentEvaluation(): Evaluation {
    return {
      userId: this.userId,
      lessonId: this.lessonId,
      score: {
        grade: this.grade,
        feedBack: this.feedback,
        success: this.hasSucceeded,
      },
      courseId: this.currentCourseInfo!.id!,
      archived: false,
    };
  }
}
