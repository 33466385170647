












import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Lesson } from '@/models/CoursesModels';

const coursesTemplateModule = namespace('CoursesTemplateModule');

@Component({})
export default class EvaluationLesson extends Vue {
  public lesson: Lesson|null = null;

  @Prop({
    required: true,
  })
  public lessonId!: string;

  @coursesTemplateModule.Action
  public fetchLessonById!: (lessonId: string) => Promise<Lesson | null>;

  public created(): void {
    this.fetchLessonById(this.lessonId).then((lesson) => this.lesson = lesson);
  }
}
