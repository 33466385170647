














import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ImageUserProfile extends Vue {
  @Prop({
    required: true,
  })
  public url!: string;

  @Prop({
    required: true,
  })
  public username!: string;

  @Prop({
    required: true,
  })
  public email!: string;
}
