


























import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationChartData, NavigationPayload } from '@/models/NavigationModels';
import { EdgeType } from '@/enums/chartData';
import EmulatorView from '@/components/viewers/EmulatorView.vue';
import { Emulator, EmulatorComponent } from '@/models/EmulatorModels';
import { UISnackBar } from '@/models/UIModels';
import NavigationReportChartCarousel from '@/components/navigationReport/NavigationReportChartCarousel.vue';
import { EmulatorTriggerType } from '@/enums/emulator';

const navigationReportModule = namespace('NavigationReportModule');
const emulatorModule = namespace('EmulatorModule');
const globalUI = namespace('GlobalUIModule');

@Component({
  components: {
    EmulatorView,
    NavigationReportChartCarousel,
  },
})
export default class NavigationReportChart extends Vue {

  public config: any = {
    theme: 'neutral',
    securityLevel: 'loose',
  };

  public chartData: any[] = [];
  public isEmulatorOpen: boolean = false;
  public isEmulatorLoading: boolean = false;

  @Prop({
    required: true,
  })
  public emulatorId!: string;

  @Prop({
    required: true,
  })
  public sessionId!: string;

  @globalUI.Action
  public showSnackBar!: (snackBar: UISnackBar) => void;

  @emulatorModule.Getter
  public currentEmulator!: Emulator;

  @emulatorModule.Getter
  public currentEmulatorPane!: EmulatorComponent;

  @emulatorModule.Action
  public fetchEmulator!: (emulatorId: string) => Promise<void>;

  @emulatorModule.Action
  public chooseCurrentEmulatorPane!: (emulatorPane: EmulatorComponent | null) => void;

  @navigationReportModule.Action
  public fetchNavigationReport!: (sessionId: string) => Promise<void>;

  @navigationReportModule.Getter
  public navigationReport!: NavigationPayload[];

  @emulatorModule.Mutation
  public setExpandedMenuItems!: (tree: any []) => void;

  @emulatorModule.Mutation
  public setCurrentIndexItemScroll!: (previousIdx: number) => void;

  @emulatorModule.Mutation
  public setMaxIndexItemScroll!: (MaxIdx: number) => void;

  @emulatorModule.Mutation
  public setDialogNotSupportedOpen!: (isOpen: boolean) => void;

  public closeViewer(): void {
    this.isEmulatorOpen = false;
  }

  public created() {
    this.fetchNavigationReport(this.sessionId)
      .then(() => this.formatNavigationReportDataChart())
      .then((chartData) => {
        this.chartData = chartData;
      });
  }

  public async clickElement(nodeId: string, navigationPayload: NavigationPayload = {}) {
    this.isEmulatorOpen = true;
    this.isEmulatorLoading = true;
    await this.chooseCurrentEmulatorPane(null);
    await this.setExpandedMenuItems([]);
    if (!this.currentEmulator || this.currentEmulator.id !== this.emulatorId) {
      await this.fetchEmulator(this.emulatorId);
      document.title = this.currentEmulator.title;
    }
    if (nodeId) {
      // Clicking on a pane in the graph
      await this.chooseCurrentEmulatorPane(this.currentEmulator!.panes![nodeId]);
    } else {
      // Clicking on a trigger in the carousel
      await this.switchActionOnTriggerType(navigationPayload);
    }
    this.isEmulatorLoading = false;
  }

  public onClickCarouselItem(navigationPayload: NavigationPayload): void {
    this.clickElement('', navigationPayload);
  }

  private async formatNavigationReportDataChart(): Promise<any> {
    let chartData: any[] = [];
    await this.navigationReport.forEach((element, index) => {
      const navigationChart: NavigationChartData | null = this.formatChartData(
        index,
        element,
        this.navigationReport[index - 1],
      );
      if (navigationChart !== null) {
        chartData = this.pushInChartData(index, navigationChart, chartData);
      }
    });
    return chartData;
  }

  // eslint-disable-next-line
  private formatChartData(id: number, nextItem: NavigationPayload, previousItem?: NavigationPayload) {
    const navigationChart: NavigationChartData = {
      id: '',
      text: '',
      link: '',
      edgeType: EdgeType.ROUND,
      next: [],
      editable: true,
    };
    if (nextItem) {
      if (nextItem.paneId) {
        navigationChart.id = nextItem.paneId;
        navigationChart.text = nextItem.paneId;
        if (nextItem.triggeredAction) {
          navigationChart.link = `-- ${id + 1} -->`;
          if (nextItem.triggeredAction.type === EmulatorTriggerType.EXPAND_PANEL) {
            navigationChart.next.push(nextItem.paneId);
          } else if (nextItem.triggeredAction.type === EmulatorTriggerType.PREV) {
            navigationChart.next.push(nextItem.navData?.previousEmulatorPane as string);
          } else if (nextItem.triggeredAction.payload && nextItem.triggeredAction.payload.location) {
            navigationChart.next.push(nextItem.triggeredAction.payload.location);
          }
        }
        return navigationChart;
      }
    }
    return null;
  }

  private pushInChartData(id: number, obj: NavigationChartData, arr: NavigationChartData[]): NavigationChartData[] {
    const sameIndex = _.findIndex(arr, _.omit(obj, 'link'));
    if (arr.length > 1) {
      if (sameIndex === -1) {
        arr.push(obj);
        return arr;
      } else {
        const regex = /\d+/g;
        const numbStr = arr[sameIndex].link;
        const matches = numbStr.match(regex);
        arr[sameIndex].link = `-- ${matches},${id + 1} -->`;
        return arr;
      }
    } else {
      arr.push(obj);
      return arr;
    }
  }

  private async switchActionOnTriggerType(navigationPayload: NavigationPayload): Promise<void> {
    let locationId: string;
    let idPreviousPane: string;

    if (navigationPayload) {
      if (navigationPayload.triggeredAction) {
        switch (navigationPayload.triggeredAction.type) {
          case EmulatorTriggerType.NAVIGATE:
            if (navigationPayload.triggeredAction.payload) {
              locationId = navigationPayload.triggeredAction.payload!.location!;
              await this.chooseCurrentEmulatorPane(this.currentEmulator!.panes![locationId]);
              await this.setExpandedMenuItems(navigationPayload.navData!.expandedMenuItems!);
            }
            break;
          case EmulatorTriggerType.EXPAND_PANEL:
            if (navigationPayload.triggeredAction.payload) {
              if (navigationPayload.navData!.expandedMenuItems) {
                await this.chooseCurrentEmulatorPane(this.currentEmulator!.panes![navigationPayload.paneId!]);
                await this.setExpandedMenuItems(navigationPayload.navData!.expandedMenuItems);
              }
            }
            break;
          case EmulatorTriggerType.SCROLL_ITEM:
            if (navigationPayload.navData) {
              await this.chooseCurrentEmulatorPane(this.currentEmulator!.panes![navigationPayload.paneId!]);
              await this.setCurrentIndexItemScroll(navigationPayload.navData.currentIndexItemScroll!);
              await this.setMaxIndexItemScroll(navigationPayload.navData.maxIndexItemScroll!);
              await this.setExpandedMenuItems(navigationPayload.navData.expandedMenuItems!);
            }
            break;
          case EmulatorTriggerType.PREV:
            if (navigationPayload.navData) {
              idPreviousPane = navigationPayload.navData!.previousEmulatorPane!;
              await this.chooseCurrentEmulatorPane(this.currentEmulator!.panes![idPreviousPane]);
              await this.setExpandedMenuItems(navigationPayload.navData.expandedMenuItems!);
            }
            break;
          case EmulatorTriggerType.CLICK_NOT_SUPPORTED:
            if (navigationPayload.navData) {
              await this.chooseCurrentEmulatorPane(this.currentEmulator!.panes![navigationPayload.paneId!]);
              await this.setDialogNotSupportedOpen(navigationPayload.navData.dialogNotSupportedOpen!);
            }
            break;
        }
      }
    }
    return;
  }
}
