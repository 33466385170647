






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { SessionInfo } from '@/models/EvaluationModels';
import EvaluationStatistics from '@/components/coursesPlanner/evaluation/EvaluationStatistics.vue';
import EvaluationLesson from '@/components/coursesPlanner/evaluation/EvaluationLesson.vue';
import EvaluationForm from '@/components/coursesPlanner/evaluation/EvaluationForm.vue';

const EvaluationTabs = {
  statistics: {
    key: 'evaluation.statistics.title',
    name: 'statistics',
    component: EvaluationStatistics,
  },
  lesson: {
    key: 'evaluation.lesson.title',
    name: 'lesson',
    component: EvaluationLesson,
  },
  evaluation: {
    key: 'evaluation.title',
    name: 'evaluation',
    component: EvaluationForm,
  },
};

@Component({
  components: {
    EvaluationStatistics,
    EvaluationLesson,
    EvaluationForm,
  },
})
export default class EvaluationTab extends Vue {
  @Prop({
    required: true,
  })
  public sessionInfo!: SessionInfo;

  private tab = null;
  private evaluationTabs: any = EvaluationTabs;

}
