


















import { Component, Prop, Vue } from 'vue-property-decorator';
import ElementsCountCard from '@/components/shared/ElementsCountCard.vue';
import { namespace } from 'vuex-class';
import { Session } from '@/models/EvaluationModels';

const evaluationModule = namespace('EvaluationModule');

@Component({
  components: {
    ElementsCountCard,
  },
})
export default class EvaluationStatistics extends Vue {
  @Prop({
    required: true,
  })
  public sessionId!: string;

  @evaluationModule.Getter
  public session!: Session | null;

  @evaluationModule.Mutation
  public setSession!: (session: Session | null) => void;

  @evaluationModule.Action
  public fetchSessionById!: (sessionId: string) => Promise<void>;

  public clickCounts: number = 0;

  public time: number = 0;

  public created(): void {
    this.fetchSessionById(this.sessionId).then(() => {
      if (this.session) {
        this.clickCounts = (this.session.totalClicks) ? this.session.totalClicks : 0;
        if (this.session.creationDate && this.session.lessonCompletedOn) {
          const startDate = new Date(this.session.creationDate);
          const endDate = new Date(this.session.lessonCompletedOn);
          this.time = new Date(endDate.getTime() - startDate.getTime()).getTime();
        }
      }
    });
  }

  public destroyed(): void {
    this.setSession(null);
  }
}
