





















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { NavigationPayload } from '@/models/NavigationModels';
import { EmulatorTriggerType } from '@/enums/emulator';

@Component({})
export default class NavigationReportChartCarousel extends Vue {
  public carousel: number = 0;

  @Prop({
    required: true,
  })
  public navigationReport!: NavigationPayload[];

  @Emit('onClickCarouselItem')
  public sentCarouselItemClicked(navigationPayload: NavigationPayload): NavigationPayload {
    return navigationPayload;
  }

  private get maxItems(): number {
    return this.navigationReport.length;
  }

  private navigate(direction: number): void {
    this.carousel = this.carousel + direction;
    this.sentCarouselItemClicked(this.navigationReport[this.carousel]);
  }

  private isReportNavigationAction(report: NavigationPayload): boolean {
    return report.triggeredAction?.type === EmulatorTriggerType.NAVIGATE;
  }

  private isReportExpandAction(report: NavigationPayload): boolean {
    return report.triggeredAction?.type === EmulatorTriggerType.EXPAND_PANEL;
  }

  private isReportPreviousAction(report: NavigationPayload): boolean {
    return report.triggeredAction?.type === EmulatorTriggerType.PREV;
  }
}
